import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

import { Advert } from '../_models/advert';

import { Response } from "@angular/http"

@Injectable({
  providedIn: 'root'
})
export class RestApiService {

  baseUrl = 'https://app.xss.kz';
  apiUrl = 'https://api.xss.kz/api/v1';

  /**
   * Constructor of the Service with Dependency Injection
   * @param http The standard Angular HttpClient to make requests
   */
  constructor(private http: HttpClient) { }

  getFilterData(): Observable<any> {
    return this.http.get(`${this.apiUrl}/filter`).pipe(
      map(results => results)
    );
  }

  getCategoryProperties(category: any, create: boolean): Observable<any> {
    return this.http.get(`${this.apiUrl}/filter/category/${category}`, { params: { add: create ? "1" : "0" } }).pipe(
      map(results => results)
    );
  }

  getAuthenticateToken(key: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/passport?key=${key}`).pipe(
      map(results => results)
    );
  }

  storePost(advert: Advert): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/add`, advert).pipe(
      map(results => results)
    );
  }

  updatePost(id: string, advert: Advert): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/a/${id}`, advert).pipe(
      map(results => results)
    );
  }

  deletePost(id: string): Observable<any> {
    return this.http.delete(`${this.apiUrl}/a/${id}`).pipe(
      map(results => results)
    );
  }

  storeFavorite(id: number): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/favorites/${id}`, '').pipe(
      map(results => results)
    );
  }

  getSearchResult(data: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/search`, { params: data }).pipe(
      map(results => results)
    );
  }

  getEditResult(id: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/my/a/${id}`).pipe(
      map(results => results)
    );
  }

  getMyResult(): Observable<any> {
    return this.http.get(`${this.apiUrl}/mylist`).pipe(
      map(results => results)
    );
  }

  getDetailResult(id: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/a/${id}`).pipe(
      map(results => results)
    );
  }

  getFavoritesResult(): Observable<any> {
    return this.http.get(`${this.apiUrl}/favorites`).pipe(
      map(results => results)
    );
  }

  storeImage(images: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/upload/photo`, { images: images }).pipe(
      map(results => results)
    );
  }

  storeReport(message: any, id: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/a/${id}/reports`, { message: message }).pipe(
      map(results => results)
    );
  }

}
